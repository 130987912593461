import { gql } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';
import { addSurveys, addCohortStatistics, setTotalCount } from 'lib/redux/actions';

const GET_SURVEYS = gql`
  query surveysList($cohort: String, $limit: Int, $skip: Int) {
    surveys(cohort: $cohort, limit: $limit, skip: $skip) {
      totalCount
      surveys {
        id
        created
        completed
        survey {
          you {
            id
            name
            network
            email
            leader
            gender
            age
            industry
            department
            employeeCount
          }
          network {
            id
            name
            network
            where
            company
            position
            leader
            gender
            age
            sameEthnicity
            years
            relationship
            work
            career
            support
            growth
            mentoring
            friend
            motivators
            innovators
          }
        }
      }
      cohortStatistics {
        networkSizeAverage
        opennessAverages {
          too_open
          very_open
          open
          somewhat_closed
          closed
          very_closed
        }
        depthAverages {
          very_distant
          not_close
          close
          very_close
        }
        boundariesAverages {
          demographic
          geographic
          horizontal
          stakeholder
          vertical
        }
        resourceAverages {
          career
          friend
          growth
          innovators
          mentoring
          motivators
          support
          work
        }
      }
    }
  }
`;

const GET_SURVEYS_GROUPED_BY_USER = gql`
  query getSurveysByUser($cohort: String, $limit: Int, $skip: Int, $order: Int) {
    getSurveysByUser(cohort: $cohort, limit: $limit, skip: $skip, order: $order) {
      totalCount
      surveys {
        user {
          id
          email
          name
          createdAt
          updatedAt
        }
        surveysStarted
        surveysCompleted
        latestUpdate
      }
    }
  }
`;

const useSurveysByCohort = (cohort, skip = 1, limit = 100) => {
  const dispatch = useDispatch();
  const response = withSessionHandler(
    useQuery(GET_SURVEYS, {
      variables: {
        cohort: cohort.id,
        skip,
        limit,
      },
      fetchPolicy: 'no-cache',
      skip: !cohort.id,
    })
  );

  useEffect(() => {
    if (response.data) {
      const { surveys, cohortStatistics } = response.data.surveys;

      dispatch(addSurveys(surveys));
      dispatch(addCohortStatistics(cohortStatistics, cohort));
      dispatch(setTotalCount(response.data.surveys.totalCount));
    }
  }, [response]);

  return { loading: response.loading, error: response.error };
};

export const useSurveyGroupedByUser = (cohort, skip = 0, limit = 100, order = -1) => {
  const cohortId = typeof cohort === 'string' ? cohort : cohort.id;
  const response = withSessionHandler(
    useQuery(GET_SURVEYS_GROUPED_BY_USER, {
      variables: {
        cohort: cohortId,
        skip,
        limit,
        order,
      },
      fetchPolicy: 'no-cache',
      skip: !cohort,
    })
  );

  return {
    loading: response.loading,
    error: response.error,
    data: response.data?.getSurveysByUser,
  };
};

export default useSurveysByCohort;
